import { SwipeGame } from "./Game";

export interface CardConfig {
  image: string;
  title: string;
  points?: CardPoints;
  answer?: "like" | "dislike";
  question?: string;
}

export interface CardPoints {
  like: number;
  dislike: number;
}

export class Card {
  game: SwipeGame;
  config: CardConfig;
  card!: HTMLDivElement;
  question!: HTMLDivElement;
  cardIndex: number;

  indicatorImage!: HTMLImageElement;
  indicator!: HTMLDivElement;
  indicatorText!: HTMLDivElement;

  onSwipe: () => void;

  constructor(
    game: SwipeGame,
    config: CardConfig,
    onSwipe: () => void,
    cardIndex: number
  ) {
    this.game = game;
    this.config = config;
    this.createCardElement();
    this.onSwipe = onSwipe;
    this.cardIndex = cardIndex;
  }

  dragStart: number | null = null;
  rotation: number = 0;
  maxRotation = 10;
  swiping: boolean = false;

  release() {
    if (this.swiping) {
      return;
    }
    this.card.classList.remove("cursor-grabbing");
    this.card.classList.add("hover:cursor-grab");
    this.dragStart = null;
    if (Math.abs(this.rotation) > this.maxRotation / 2) {
      this.swipe();
      return;
    }
    this.card.style.transform = "";
    this.indicator.style.opacity = "0%";
  }

  swipe() {
    this.swiping = true;
    const direction = this.rotation > 0 ? "like" : "dislike";
    // this.game.cardContainer.removeChild(this.card);

    this.card.style.zIndex = "10";

    if (direction === "like") this.card.classList.add("animate-totheleft");

    if (direction === "dislike") this.card.classList.add("animate-totheright");

    if (this.config.points) {
      const points = this.config.points[direction];
      this.game.addPoints(points);
    }

    let rightAnswer = undefined;

    if (this.config.answer) {
      if (direction === this.config.answer) {
        this.game.addPoints(1);
        rightAnswer = true;
      } else {
        this.game.loseLife();
        rightAnswer = false;
      }
    }

    this.game.playerData.swipes.push({
      card: this.toString(),
      swipe: direction,
      rightAnswer: rightAnswer,
    });

    this.onSwipe();
  }

  destroy() {
    this.card.remove();
  }

  toString() {
    let string = "";
    if (this.config.question) {
      string += this.config.question + " - ";
    }
    string += this.config.title;
    return string;
  }

  createCardElement() {
    this.card = document.createElement("div");

    this.card.className =
      "flex flex-col w-full h-full overflow-hidden absolute hover:cursor-grab touch-none";

    this.card.style.border = `${this.game.config.cardBorderWidth}px solid ${this.game.config.cardBorderColor}`;
    this.card.style.backgroundColor = `${this.game.config.cardColor}`;
    this.card.style.borderRadius = `${this.game.config.cardBorderRadius}px`;

    this.card.style.fontFamily = this.game.config.cardTitleFont || "inherit";
    this.card.style.fontSize = this.game.config.cardTitleSize + "px";
    this.card.style.color = this.game.config.cardTitleColor;

    this.card.onanimationend = () => this.destroy();

    if (this.cardIndex === 0) this.card.style.zIndex = "5";
    else this.card.style.zIndex = "2";

    let img = document.createElement("div");
    img.className = "h-full w-full bg-center bg-cover bg-no-repeat relative";
    img.style.padding = this.game.config.indicatorPadding + "px";

    img.style.backgroundImage = `url(${this.config.image})`;
    img.style.backgroundColor = "black";

    this.indicator = document.createElement("div");
    this.indicator.className =
      "aspect-square rounded-full flex items-center justify-center absolute text-black";

    this.indicator.style.width = this.game.config.indicatorWidth + "px";
    this.indicatorText = document.createElement("div");

    this.indicatorText.style.fontSize = `${
      this.game.config.indicatorWidth / 4
    }px`;

    this.indicator.appendChild(this.indicatorText);

    img.appendChild(this.indicator);

    this.indicatorImage = document.createElement("img");
    this.indicatorImage.className = "absolute";
    this.indicatorImage.style.width = this.game.config.indicatorWidth + "px";

    this.indicator.appendChild(this.indicatorImage);

    this.card.appendChild(img);

    if (this.game.config.showCardTitle) {
      let title = document.createElement("div");
      title.className = "text-center py-2";
      title.style.paddingRight = `${this.game.config.cardBorderRadius}px`;
      title.style.paddingLeft = `${this.game.config.cardBorderRadius}px`;

      title.innerHTML = this.config.title.replaceAll("_", " ");
      this.card.appendChild(title);
    }

    this.game.cardContainer.appendChild(this.card);
  }

  setIndicatorStyle() {
    const direction = this.rotation > 0 ? "right" : "left";

    this.indicator.style.opacity =
      Math.pow(Math.abs(this.rotation) / this.maxRotation, 2) * 100 + "%";

    let img;

    if (direction === "left") {
      this.indicator.style.left = "unset";
      this.indicator.style.right = this.game.config.indicatorPadding + "px";
      img = this.game.config.swipeLeftIndicatorImage;
    } else {
      this.indicator.style.right = "unset";
      this.indicator.style.left = this.game.config.indicatorPadding + "px";
      img = this.game.config.swipeRightIndicatorImage;
    }

    if (img === "false") {
      this.indicatorImage.hidden = true;
      return;
    }

    if (img) {
      this.indicator.style.backgroundColor = "transparent";
      this.indicatorImage.hidden = false;
      this.indicatorImage.src = img;
      this.indicatorImage.style.top = "0";
      this.indicatorText.innerHTML = "";

      if (direction === "left") {
        this.indicatorImage.style.right = "0";
        this.indicatorImage.style.left = "unset";
        return;
      }
      this.indicatorImage.style.left = "0";
      this.indicatorImage.style.right = "unset";
      return;
    }

    this.indicatorImage.hidden = true;

    this.indicator.style.backgroundColor =
      direction === "left" ? "red" : "green";
    this.indicatorText.innerHTML = direction === "left" ? "NEE" : "JA";
  }
}
